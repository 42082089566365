import React from 'react';
import { Card, CardContent, Typography, Button, Box, getTabScrollButtonUtilityClass } from '@mui/material';
import { CenterFocusWeak } from '@mui/icons-material';
import DataApiLogo from '../../../assets/images/hiw-left.png';
import solibrilogo from '../../../assets/images/android-chrome-192x192.png';
import { navigate } from 'gatsby';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const OpenAPICard = () => {
  const { t, i18n } = useTranslation();

  const customTheme = useTheme();
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '80vh',
      gap: '50px',
      MarginTop: '10px',
      background: customTheme.palette.mode === 'light'
        ? '#ffff'
        : '#1f1f1f',

    },
    card: {
      display: 'flex',
      flexDirection: 'row', // Make the card and image side by side
      alignItems: 'flex-start', // Align items to the top
      padding: '16px',
      width: '600px',
      height: '350px',
      MarginTop: '10px',
    },
    cardContent: {
      flex: 1, // Allow the card content to expand to fill available space
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      fontFamily: 'var(--font-family-default)',
      fontStyle: 'normal',
      tabSize: 4, // Space between the content and the button
    },
    image: {
      width: '250px', // Set the width of the image
      marginRight: '16px', // Add some margin to separate the image from the card content
    },
  };


  const handleapi = () => {
    navigate('/openapi');
  };
  return (
    <div style={styles.container}>
      <Card style={styles.card}>
        <img src={DataApiLogo} alt="Logo" style={styles.image} />
        <CardContent style={styles.cardContent}>
          <div>
            <Typography variant="h5" component="div" style={{ fontWeight: 700 }} sx={{
              py: 2,
              lineHeight: 1.6,

            }}>
             
              {t('catalog.ssotext')}  
            </Typography>
            <Typography variant="p">
              {t('catalog.ssotext2')}  
           
            </Typography>
          </div>
          <div>
            <Button variant="contained" startIcon={<CenterFocusWeak />} style={{ marginTop: '116px' }}
              onClick={handleapi}>
         
              {t('catalog.button')}  
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default OpenAPICard;
