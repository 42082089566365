import React from 'react';
import OpenAPICard from '../Content';


const PageContainer = () => {
  return (
    <>
      <OpenAPICard />
    </>
  );
};

export default PageContainer;
