import React, { useEffect } from 'react';

import Catalog from '../components/Catalog';


const Catalog2 = () => {

  return (<>
    <Catalog />
    
  </>
  );
};

export default Catalog2;